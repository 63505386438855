import { css } from "@emotion/react";
import React, { ComponentProps } from "react";
import footerFlourish from "../../assets/footerFlourish.svg";
import { horizontalContentWidth } from "../../margins";
import { bpLarge, colorBlue, bpSmall, bpMedium } from "../../stylingConstants";
import SiteNavigation from "./SiteNavigation";
import SocialLinks from "../Atoms/SocialLinks";

type Props = ComponentProps<"footer">;

const footerCss = css`
  width: 100%;
  color: white;
  background-color: ${colorBlue};
  background-image: url(${footerFlourish});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 264px;
  padding: 0.1px;
  box-sizing: border-box;
  @media ${bpSmall} {
    background-size: 375px;
  }
  @media ${bpMedium} {
    background-size: 537px;
  }
  @media ${bpLarge} {
    background-size: 710px;
  }
`;

const footerContentCss = css`
  ${horizontalContentWidth}
  margin-top: 56px;
  margin-bottom: 18px;
  @media ${bpLarge} {
    margin-top: 80px;
  }
`;

export default function Footer(props: Props) {
  return (
    <div
      css={css`
        overflow-y: hidden;
        background-color: white;
      `}
    >
      <footer css={footerCss} {...props}>
        <section css={footerContentCss}>
          <SiteNavigation theme="onDark" />
          <SocialLinks theme="onDark" />
        </section>
      </footer>
    </div>
  );
}
