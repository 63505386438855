import styled from "@emotion/styled";
import { bpLarge } from "../../stylingConstants";
import downArrow from "../../assets/downArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";
import { captionTypeface } from "./Text";

export default styled.a<{ direction?: "left" | "down" }>`
  font-family: ${captionTypeface};
  font-size: 14px;
  line-height: 12px;
  @media ${bpLarge} {
    font-size: 16px;
    line-height: 16px;
  }
  :before {
    content: url(${({ direction }) => (direction === "down" ? downArrow : leftArrow)});
    vertical-align: middle;
    margin-right: 12px;
    position: relative;
    top: -0.6px;
    .arrowFigure {
      fill: red;
    }
  }
`;
