const yearBasePath = "/arkiv";

export const routeHelper = {
  /** 
   * @param {{ slug?: string | null, id: string }} work 
   */
  getWorkRoute(work: { slug?: string | undefined | null, id?: string | undefined | null }): string {
    return `/bidrag/${work.slug || work.id}`;
  },

  yearBasePath,

  /** 
   * @param {number} year 
   */
  getYearRoute(year: number): string {
    return `${yearBasePath}/${year}`;
  },

  /** 
   * @param {string} path 
   */
  getCurrentYearFromPath(path: string | undefined): string | null {
    if (!path || path.indexOf(yearBasePath) !== 0) {
      return null;
    }
    const trimmedBasePath = path.substr(yearBasePath.length);
    return trimmedBasePath.substr(0, trimmedBasePath.indexOf("/") || undefined);
  },

  /** 
   * @param {string} slug 
   */
  getPersonRoute(slug: string): string {
    return `/person/${slug}`;
  },

  /** 
   * @param {{ slug?: string | null, id: string }} person
   * @param {string} year
   */
  getPersonInYearRoute(person: { slug?: string | null, id: string }, year: string): string {
    return `/${year}/${person?.slug || person?.id}`;
  },
};
