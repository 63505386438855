// import React from "react";
// import { MainLayout } from "./src/components/Organisms/Layout";

// export const wrapPageElement = ({ element }) => {
//   return <MainLayout>{element}</MainLayout>;
// };

// gatsby-browser.js
import React from "react";
import { MainLayout } from "./src/components/Organisms/Layout";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props; // Grab the pageContext from the `props` object
  return (
    <MainLayout {...props} pageContext={pageContext}>
      {element}
    </MainLayout>
  );
};
