import { css, CSSObject } from "@emotion/react";
import {
  bpSmall,
  bpMedium,
  bpLarge,
  formatMinWidthMedia
} from "./stylingConstants";

const maxWidth = 1400;
const largeHorizontalMargin = 40;

//This is totally vanity code. But sometimes you just have to.
const repeatCssModifiers = <TValue extends number | string = number | string>(
  propName: string,
  modifiers: string[]
) => (value: TValue) => {
  const cssValue = typeof value === "number" ? `${value}px` : value;
  return modifiers.reduce((cssObject, modifier) => {
    cssObject[`${propName}${modifier}`] = cssValue;
    return cssObject;
  }, {} as CSSObject);
};

export const horizontalMargin = repeatCssModifiers("margin", ["Left", "Right"]);
export const verticalMargin = repeatCssModifiers("margin", ["Top", "Bottom"]);
export const horizontalPadding = repeatCssModifiers("padding", [
  "Left",
  "Right"
]);
export const verticalPadding = repeatCssModifiers("padding", ["Top", "Bottom"]);

export const horizontalContentWidth = css`
  ${horizontalMargin(16)}
  @media ${bpSmall} {
    ${horizontalMargin(24)}
  }
  @media ${bpMedium} {
    ${horizontalMargin(40)}
  }
  @media ${bpLarge} {
    ${horizontalMargin(largeHorizontalMargin)}
  }
  @media ${formatMinWidthMedia(maxWidth + largeHorizontalMargin * 2)} {
    max-width: ${maxWidth}px;
    margin-left: auto;
    margin-right: auto;
  }
`;
