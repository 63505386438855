export const formatMinWidthMedia = (minWidth: number) =>
  `only screen and (min-width: ${minWidth}px)`;

export const bpSmall = formatMinWidthMedia(640);
export const bpMedium = formatMinWidthMedia(1020);
export const bpLarge = formatMinWidthMedia(1280);

export const colorBlue = "#222536";
export const colorGoldFill = "#F3EEE6";
export const colorGoldStroke = "#B0A27E";
export const colorBronzeStroke = "#B0967E"
export const colorGrayFill = "#F3F3F4";
export const colorGrayStroke = "#8E9DA4";
export const colorDark = "#1B1B1B";
export const colorLight = "#b7b7b7";

export const sizeAtom = 4;
