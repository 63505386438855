import styled from "@emotion/styled";
import MaybeExternalLink from "./MaybeExternalLink";
import { colorBlue } from "../../stylingConstants";

const TextLink = styled(MaybeExternalLink)`
  color: ${colorBlue};
  text-decoration: underline;
`;

export default TextLink;
