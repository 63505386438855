import React from "react";
import { css } from "@emotion/react";
import { bpLarge, colorGoldStroke } from "../../stylingConstants";
import styled from "@emotion/styled";
import { Caption } from "./Text";
import { Link } from "gatsby";
import { MyNewsDeskLogo, TwitterLogo, InstagramLogo, FacebookLogo } from "./Icons";
import { useGlobalSiteData } from "../../hooks";

export const SocialMediaItem = styled.li`
  display: inline-block;
  padding-right: 16px;
  @media ${bpLarge} {
    padding-right: 24px;
  }
`;

const PolicyLink = Caption.withComponent(Link);

export default function SocialLinks({ theme = "onDark", spacious = true }: { theme: "onLight" | "onDark"; spacious?: boolean }) {
  const { socialUrls: urls, privacyPolicyUrl } = useGlobalSiteData();
  const logoFill = theme === "onLight" ? colorGoldStroke : undefined;
  return (
    <>
      <ul
        css={css`
          list-style: none;
          margin: ${spacious ? "36px 0 52px 0" : "0 0 8px 0"};
          padding: 0;
          ${spacious &&
          css`
            @media ${bpLarge} {
              margin-top: 52px;
              margin-bottom: 62px;
            }
          `}
        `}
      >
        {urls.facebook && (
          <SocialMediaItem>
            <a href={urls.facebook}>
              <FacebookLogo fill={logoFill} />
            </a>
          </SocialMediaItem>
        )}
        {urls.instagram && (
          <SocialMediaItem>
            <a href={urls.instagram}>
              <InstagramLogo fill={logoFill} />
            </a>
          </SocialMediaItem>
        )}
        {urls.twitter && (
          <SocialMediaItem>
            <a href={urls.twitter}>
              <TwitterLogo fill={logoFill} />
            </a>
          </SocialMediaItem>
        )}
        {urls.mynewsdesk && (
          <SocialMediaItem>
            <a href={urls.mynewsdesk}>
              <MyNewsDeskLogo fill={logoFill} />
            </a>
          </SocialMediaItem>
        )}
      </ul>
      {privacyPolicyUrl && (
        <PolicyLink
          css={css`
            color: ${theme === "onDark" ? "#6f7180" : "inherit"};
          `}
          to={privacyPolicyUrl}
        >
          Om personuppgifter
        </PolicyLink>
      )}
    </>
  );
}
