import React, { PropsWithChildren, ReactNode, forwardRef, Ref } from "react";
import { css } from "@emotion/react";
import { bpMedium } from "../../stylingConstants";
import { horizontalMargin } from "../../margins";

type Props = {
  leftColumn?: ReactNode;
  rightColumn?: ReactNode;
  children?: ReactNode;
};

export const contentLargeWidth = 578;
export const contentSmallWidth = 386;

const constrainedWidthCss = css`
  ${horizontalMargin("auto")}
  width: ${contentSmallWidth}px;
  max-width: 100%;
  order: 2;
  @media ${bpMedium} {
    width: ${contentLargeWidth}px;
  }
`;

const asideWidthCss = css`
  ${constrainedWidthCss};
  flex: 1;
  @media ${bpMedium} {
    width: unset;
    ${horizontalMargin("auto")}
  }
`;

export default forwardRef(function ContentPageLayout({ children, leftColumn, rightColumn, ...props }: Props, ref: Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        @media ${bpMedium} {
          flex-direction: row;
          justify-content: space-between;
        }
      `}
      {...props}
    >
      <article css={constrainedWidthCss}>{children}</article>
      <aside css={[asideWidthCss, { order: 1 }]}>{leftColumn}</aside>
      <aside
        css={[
          asideWidthCss,
          css`
            text-align: left;
            @media ${bpMedium} {
              text-align: center;
            }
          `,
        ]}
      >
        {rightColumn}
      </aside>
    </div>
  );
});
