import React, { ComponentProps, PropsWithChildren, useMemo } from "react";
import GlobalStyle from "../../GlobalStyle";
import { css } from "@emotion/react";
import CookieBanner from "../Atoms/CookieBanner";
import Header from "./Header";
import Footer from "./Footer";
import MainContent from "./Main";
import { useDelayedFollower } from "../../hooks";
import { ArchiveLayout } from "./ArchiveLayout";
import BackLink from "../Atoms/BackLink";
import YearSelectMenu from "./YearSelectMenu";
import { CategoryOrRolePickerWithButton } from "./CategoryPicker";
import "../../assets/fonts/fonts.css";

const fadeOutDuration = 100;

type ContextProps = {
  children: React.ReactNode;
  location: Location;
  pageContext: {
    noWrapper?: boolean;
    year?: string;
    personSlug?: string;
    categoryName?: string;
    categories?: [string, string][];
    useArchiveLayout?: boolean;
  };
};

export const MainLayout = ({ children, location, pageContext }: ContextProps) => {
  const { renderChildren, selectedArchiveYear, personSlug, noWrapper, categoryName, useArchiveLayout } = useDelayedFollower(
    useMemo(
      () => ({
        renderChildren: children,
        selectedArchiveYear: pageContext?.year,
        personSlug: pageContext?.personSlug,
        noWrapper: pageContext?.noWrapper,
        categoryName: pageContext?.categoryName,
        useArchiveLayout: pageContext?.useArchiveLayout,
      }),
      [pageContext?.year, pageContext?.personSlug, pageContext?.noWrapper, pageContext?.categoryName, pageContext?.useArchiveLayout, children]
    ),
    fadeOutDuration
  );

  const visible = true; //renderChildren === children;
  const opacity = visible ? 1 : 0;

  const transitionStyle = {
    opacity,
    transition: `opacity ${visible ? "250ms ease-out 50ms" : `${fadeOutDuration}ms`}`,
    height: !visible ? 100000 : undefined,
  };
  //const noWrapper = false;
  const Wrapper = noWrapper ? (props: ComponentProps<"div">) => <div {...props} /> : MainContent;

  return (
    <>
      <GlobalStyle />
      <CookieBanner />
      <div
        css={css`
          position: relative;
        `}
      >
        <Header showEmbed={location?.pathname === "/"} />
        <div
          css={css`
            background: white;
            padding: 0.1px;
          `}
        >
          {selectedArchiveYear || categoryName || useArchiveLayout ? (
            <ArchiveLayout
              key="archive"
              mainContentStyle={transitionStyle}
              leftColumn={
                <>
                  {pageContext.categories && <CategoryOrRolePickerWithButton buttonProps={{ css: { marginBottom: 16 } }} categories={pageContext.categories} />}
                  <YearSelectMenu selectedOption={selectedArchiveYear} />
                </>
              }
            >
              {renderChildren}
            </ArchiveLayout>
          ) : personSlug ? (
            <ArchiveLayout mainContentStyle={transitionStyle} leftColumn={<BackLink />}>
              {children}
            </ArchiveLayout>
          ) : (
            <Wrapper id="mainContent" key="standard" style={transitionStyle}>
              {children}
            </Wrapper>
          )}
          {/* 
          <Wrapper id="mainContent" key="standard" style={transitionStyle}>
            {children}
          </Wrapper> */}
        </div>
        <Footer css={transitionStyle} />
      </div>
    </>
  );
};
