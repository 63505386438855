import { graphql, useStaticQuery } from "gatsby";
import { EffectCallback, useEffect, useMemo, useRef, useState } from "react";

export const useEffectWithDeps = <TDeps extends any[]>(
  fn: (prevDeps?: TDeps) => ReturnType<EffectCallback>,
  deps: TDeps
) => {
  const prevDeps = useRef<TDeps>();
  useEffect(() => {
    const effectResult = fn(prevDeps.current);
    prevDeps.current = deps;
    return effectResult;
  }, deps);
};

const mapMenuItems = (items?: (Queries.NavItemFragment | null | undefined)[] | null) =>
  (items?.map(mapMenuItem).filter((item) => item != null) as NonNullable<
    ReturnType<typeof mapMenuItem>
  >[]) ?? [];

const mapMenuItem = (item?: Queries.NavItemFragment | null) => {
  if (item == null) {
    return;
  }
  const url =
    item.rawUrl ?? (item.internalLink?.slug && `/${item.internalLink.slug}`);
  if (!url) {
    return;
  }
  const text = item.text ?? item.internalLink?.title;
  return { text, url };
};

const useGlobalGraphQl = () =>
  useStaticQuery(graphql`
    query GlobalSiteData {
      allContentfulYear {
        nodes {
          year
        }
      }
      contentfulSharedContent {
        menuItems {
          id
          text
          rawUrl
          internalLink {
            id
            slug
          }
        }
        desktopMenuItems {
          id
          text
          rawUrl
          internalLink {
            id
            slug
          }
        }
        facebookUrl
        instagramUrl
        mynewsdeskUrl
        twitterUrl
        headerCountdown
        headerCountdownText
        headerText
        headerLink {
          id
          text
          rawUrl
          internalLink {
            id
            slug
          }
        }
        headerEmbed
        headerEmbedImage {
          gatsbyImageData(width: 730, quality: 95)
        }
        cookieDisclaimer {
          raw
        }
        privacyPolicy {
          ... on ContentfulArticlePage {
            slug
          }
        }
      }
    }
  `);

export const useGlobalSiteData = () => {
  const { contentfulSharedContent } = useGlobalGraphQl();
  return useMemo(
    () => ({
      menuItems: mapMenuItems(contentfulSharedContent?.menuItems),
      desktopMenuItems: mapMenuItems(contentfulSharedContent?.desktopMenuItems),
      headerLink: contentfulSharedContent?.headerLink,
      socialUrls: {
        facebook: contentfulSharedContent?.facebookUrl,
        instagram: contentfulSharedContent?.instagramUrl,
        mynewsdesk: contentfulSharedContent?.mynewsdeskUrl,
        twitter: contentfulSharedContent?.twitterUrl,
      },
      cookieDisclaimer: contentfulSharedContent?.cookieDisclaimer
        ?.raw,
      privacyPolicyUrl: contentfulSharedContent?.privacyPolicy?.slug,
    }),
    [contentfulSharedContent]
  );
};

// /**
//  * Since this site is statically generated date compares needs to happe§§n after render
//  */
// export const useNominationOpenState = () => {
//   // const [openState, setOpenState] = useState(true);
//   // const {
//   //   nominationSchedule: { close, open },
//   // } = useGlobalSiteData();
//   // useEffect(() => {
//   //   setOpenState(new Date() >= open && new Date() < close);
//   // }, [open, close]);
//   // return openState;
// };

// /**
//  * Since this site is statically generated date compares needs to happen after render
//  */
export const useSiteHeaderState = () => {
  const [timeLeft, setTimeLeft] = useState<number>();
  const { contentfulSharedContent } = useGlobalGraphQl();
  useEffect(() => {
    const pollTime = () => {
      const calculatedTimeLeft =
        contentfulSharedContent?.headerCountdown != null &&
        new Date(contentfulSharedContent.headerCountdown).getTime() -
          new Date().getTime();

      setTimeLeft(calculatedTimeLeft || 0);
      return calculatedTimeLeft
        ? setTimeout(
            pollTime,
            //It's probably very possible to trigger this the moment hour/minute switches, but i got places to be.
            calculatedTimeLeft > 86400000 ? 9000000 : 20000
          )
        : null;
    };
    const poller = pollTime();
    return () => {
      poller && window.clearTimeout(poller);
    };
  }, [contentfulSharedContent?.headerCountdown]);
  return useMemo(
    () =>
      timeLeft == null
        ? { loaded: false }
        : timeLeft <= 0
        ? ({
            loaded: true,
            open: true,
            text: contentfulSharedContent?.headerText,
            link: mapMenuItem(contentfulSharedContent?.headerLink),
            embed: contentfulSharedContent?.headerEmbed
              ? {
                  src: contentfulSharedContent?.headerEmbed,
                  thumb: contentfulSharedContent?.headerEmbedImage?.fluid?.src,
                }
              : null,
          } as const)
        : ({
            loaded: true,
            open: false,
            text: contentfulSharedContent?.headerCountdownText,
            timeLeft,
            embed: contentfulSharedContent?.headerEmbed
              ? {
                  src: contentfulSharedContent?.headerEmbed,
                  thumb: contentfulSharedContent?.headerEmbedImage?.fluid?.src,
                }
              : null,
          } as const),
    [timeLeft, contentfulSharedContent]
  );
};

const yearMatch = /^\d\d(\d)\d$/;
export const useAwardDecades = () => {
  const { allContentfulYear } = useGlobalGraphQl();
  const years = allContentfulYear.nodes;
  return useMemo(() => {
    const decadeMap = years
      .sort()
      .reverse()
      .reduce((acc:any, yearData: any) => {
        const stringYear = yearData.year.toString();
        const decade = yearMatch.exec(stringYear)?.[1];
        if (decade) {
          acc.set(decade, [...(acc.get(decade) ?? []), stringYear]);
        }
        return acc;
      }, new Map<string, string[]>());
    return Array.from(decadeMap.entries());
  }, [years]);
};

// /** Use to keep things around a while for animations and the like */
export const useDelayedFollower = <T>(
  value: T,
  defaultDelay: number,
  ...delays: [T, number][]
) => {
  const [lazyValue, setLazyValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(
      () => setLazyValue(value),
      delays.find(([val]) => val === value)?.[1] ?? defaultDelay
    );
    return () => clearTimeout(timer);
  }, [value]);
  return lazyValue;
};