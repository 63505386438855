import React from "react";
import ArrowLink from "./ArrowLink";

export default function BackLink() {
  return (
    <ArrowLink direction="left" href="#" css={{ display: "block", marginBottom: 16, marginTop: 8 }} onClick={(ev) => (ev.preventDefault(), history?.back())}>
      Tillbaka
    </ArrowLink>
  );
}
