import { GatsbyLinkProps, Link } from "gatsby";
import { ReactNode } from "react";

export default function MaybeExternalLink<TState>({
  children,
  externalSuffix,
  externalTarget,
  ...props
}: GatsbyLinkProps<TState> & {
  externalSuffix?: ReactNode;
  externalTarget?: string;
}) {
  const isExternal = props.to.startsWith("http") || props.to.startsWith("mailto") || props.to.startsWith("tel");
  const LinkComponent = isExternal ? ({ to, ...rest }: { to: string; target?: string; rel?: string }) => <a target={externalTarget} href={to} {...rest} /> : Link; // For internal links, use Gatsby's Link
  return (
    <LinkComponent {...props}>
      {children}
      {isExternal && externalSuffix}
    </LinkComponent>
  );
}
