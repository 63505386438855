exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-hosts-tsx": () => import("./../../../src/templates/Hosts.tsx" /* webpackChunkName: "component---src-templates-hosts-tsx" */),
  "component---src-templates-juries-tsx": () => import("./../../../src/templates/Juries.tsx" /* webpackChunkName: "component---src-templates-juries-tsx" */),
  "component---src-templates-nominate-tsx": () => import("./../../../src/templates/Nominate.tsx" /* webpackChunkName: "component---src-templates-nominate-tsx" */),
  "component---src-templates-person-timeline-tsx": () => import("./../../../src/templates/PersonTimeline.tsx" /* webpackChunkName: "component---src-templates-person-timeline-tsx" */),
  "component---src-templates-speakers-tsx": () => import("./../../../src/templates/Speakers.tsx" /* webpackChunkName: "component---src-templates-speakers-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/Work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */),
  "component---src-templates-year-role-tsx": () => import("./../../../src/templates/YearRole.tsx" /* webpackChunkName: "component---src-templates-year-role-tsx" */),
  "component---src-templates-year-tsx": () => import("./../../../src/templates/Year.tsx" /* webpackChunkName: "component---src-templates-year-tsx" */)
}

