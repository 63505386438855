import { useMemo, Fragment } from "react";
import { Heading3, Heading5 } from "./Text";
import React from "react";
import { css } from "@emotion/react";
import { bpLarge, colorBronzeStroke, colorDark, bpMedium } from "../../stylingConstants";
import { verticalMargin, horizontalMargin } from "../../margins";

//Sorry for the math, i really did not study at KTH. Refactoring can probably cut down number of calculation significantly. At the same time, come on.
const milliSecondsInADay = 86400000;
const milliSecondsInAnHour = 3600000;
const milliSecondsInAMinute = 60000;

const Digit = Heading3.withComponent("span");
const Unit = Heading5.withComponent("span");

const pluralize = (count: number, singular: string, plural: string) => (count === 1 ? singular : plural);

export default function Countdown({ timeLeft }: { timeLeft?: number }) {
  //Should not have ended up here
  if (timeLeft == null || timeLeft <= 0) {
    return null;
  }
  const values = useMemo<[number, string][]>(() => {
    const daysLeft = Math.floor(timeLeft / milliSecondsInADay);
    const hoursLeft = Math.floor((timeLeft - daysLeft * milliSecondsInADay) / milliSecondsInAnHour);
    const hourUnit = pluralize(hoursLeft, "timme", "timmar");
    if (daysLeft) {
      return [
        [daysLeft, pluralize(daysLeft, "dag", "dagar")],
        [hoursLeft, hourUnit],
      ];
    }
    const minutesLeft = Math.ceil((timeLeft - hoursLeft * milliSecondsInAnHour) / milliSecondsInAMinute);
    return [hoursLeft ? [hoursLeft, hourUnit] : null, [minutesLeft === 0 && hoursLeft === 0 ? 1 : minutesLeft, pluralize(minutesLeft, "minut", "minuter")]].filter((value) => value != null) as [number, string][];
  }, [timeLeft]);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        ${verticalMargin(14)}
      `}
    >
      {values.map(([value, unit]) => (
        <div
          key={unit}
          css={css`
            ${horizontalMargin(18)}
            @media ${bpLarge} {
              ${horizontalMargin(22)}
            }
          `}
        >
          <Digit
            css={css`
              font-family: "Times New Roman", serif;
              color: ${colorBronzeStroke};
              vertical-align: middle;
              margin-right: 12px;
              @media ${bpMedium} {
                color: white;
                margin-right: 16px;
              }
              @media ${bpLarge} {
                margin-right: 18px;
              }
            `}
          >
            {value}
          </Digit>
          <Unit
            css={css`
              color: ${colorDark};
              text-transform: uppercase;
              vertical-align: middle;
              @media ${bpMedium} {
                opacity: 0.5;
                color: white;
              }
            `}
          >
            {unit}
          </Unit>
        </div>
      ))}
    </div>
  );
}
