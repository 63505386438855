import styled from "@emotion/styled";
import { bpMedium, bpSmall, bpLarge } from "../../stylingConstants";
import { horizontalContentWidth } from "../../margins";

//Top is padded for scroll position
const MainContent = styled.main`
  min-height: 55vh;
  padding-top: 16px;
  margin-bottom: 32px;
  ${horizontalContentWidth}
  @media ${bpSmall} {
    padding-top: 20px;
    margin-bottom: 40px;
  }
  @media ${bpMedium} {
    padding-top: 40px;
    margin-bottom: 80px;
  }
  @media ${bpLarge} {
    padding-top: 64px;
    margin-bottom: 128px;
  }
`;

export default MainContent;
