import styled from "@emotion/styled";
import { colorDark, colorBlue, bpLarge, colorBronzeStroke, colorGoldStroke, bpSmall } from "../../stylingConstants";
import MaybeExternalLink from "./MaybeExternalLink";
import { captionTypeface } from "./Text";
import { css } from "@emotion/react";

type Props = {
  placement?: "onDark" | "onLight";
};

const sharedButtonStyle = css`
  font-family: ${captionTypeface};
  font-weight: 500;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;
  border-radius: 200px;
  transition: color 250ms ease-out, background-color 250ms ease-out, box-shadow 100ms;
  cursor: pointer;
  :hover {
    transition: color 250ms ease-in, background-color 250ms ease-in;
  }
  [disabled] {
    opacity: 0.5;
  }
`;

const onLightOnDark =
  <T extends any>(onLight: T, onDark: T) =>
  (props: Props) =>
    (props.placement ?? "onLight") === "onLight" ? onLight : onDark;

export const Button = styled.button<Props>`
  ${sharedButtonStyle}
  font-size: 11px;
  line-height: 11px;
  color: ${onLightOnDark(colorDark, "inherit")};
  padding: 12px 24px;
  background-color: transparent;
  border: 1px solid ${onLightOnDark(colorBlue, colorBronzeStroke)};
  :focus {
    outline: none;
    box-shadow: 0 0 0 0.75px ${onLightOnDark(colorBlue, colorBronzeStroke)};
  }
  :hover {
    background-color: ${onLightOnDark(colorBlue, colorBronzeStroke)};
    color: white;
  }
  @media ${bpLarge} {
    padding: 14px 28px;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.3px;
  }
`;

export const FormButton = styled.button`
  border: none;
  ${sharedButtonStyle}
  font-size: 16px;
  line-height: 16px;
  color: white;
  padding: 16px 32px;
  background-color: ${colorGoldStroke};
  width: 100%;
  :hover {
    background-color: ${colorBlue};
    color: white;
  }
  @media ${bpSmall} {
    width: auto;
  }
  @media ${bpLarge} {
    padding: 18px 36px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const LinkButton = Button.withComponent(MaybeExternalLink);

export const HrefButton = Button.withComponent("a");
